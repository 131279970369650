module.exports = {
	tableColumnConfig: [
		// {
		// 	prop: "commodityName",
		// 	label: "商品名称",
		// },
		{
			prop: "skuName",
			label: "sku名称",
		},
		{
			prop: "skuCostPrice",
			label: "成本价",
		},
		{
			prop: "skuOriPrice",
			label: "原价",
		},
		{
			prop: "skuPrice",
			label: "零售价",
		},
		// {
		// 	prop: "skuVipPrice",
		// 	label: "会员价",
		// },
		{
			prop: "skuActualStocks",
			label: "库存",
		},
		{
			prop: "skuVolume",
			label: "商品体积",
		},
		{
			prop: "skuWeight",
			label: "商品重量",
		},
		{
			prop: "status",
			label: "状态",
			align: "center",
			type:"state",
			option:[{label:"禁用",value:0},{label:"启用",value:1}]
		},
		{
			prop: "skuPic",
			label: "sku图片",
			type:"img",
		}
	],
	formLabelConfig: [
		// {
		// 	prop: "commodityName",
		// 	label: "商品名称",
		// },
		// {
		// 	prop: "skuName",
		// 	label: "sku名称",
		// 	option:[],
		// 	showType:"cascader"
		// },
		{
			prop: "skuCostPrice",
			label: "成本价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuOriPrice",
			label: "原价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuPrice",
			label: "零售价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		// {
		// 	prop: "skuVipPrice",
		// 	label: "会员价",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		{
			prop: "skuActualStocks",
			label: "库存",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuVolume",
			label: "商品体积",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuWeight",
			label: "商品重量",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "status",
			label: "状态",
			showType:"select",
			option:[{label:"禁用",value:0},{label:"启用",value:1}]
		},
		{
			prop: "skuPic",
			label: "规格图片",
			showType:"imgUploader",
			limitNum:1
		}
	],
	formLabelConfig2: [
		// {
		// 	prop: "commodityName",
		// 	label: "商品名称",
		// },
		{
			prop: "skuName",
			label: "规格名称",
			option:[],
			showType:"cascader"
		},
		{
			prop: "skuCostPrice",
			label: "成本价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuOriPrice",
			label: "原价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuPrice",
			label: "零售价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuVipPrice",
			label: "会员价",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuActualStocks",
			label: "库存",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "skuVolume",
			label: "商品体积",
		},
		{
			prop: "skuWeight",
			label: "商品重量",
		},
		{
			prop: "status",
			label: "状态",
			showType:"select",
			option:[{label:"禁用",value:0},{label:"启用",value:1}]
		},
		{
			prop: "skuPic",
			label: "规格图片",
			showType:"imgUploader",
			limitNum:1
		}
	],
	formDataProp:{
		labelName:"",
		labelCode:"",
		
		skuActualStocks:0,
		skuVolume:"",
		skuWeight:0,
		status:1,
		skuPic:""
	}
}
